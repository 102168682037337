import { typesWithElements } from '@visma/formula/lib/utils';
import { useCurrentUser } from '@visma/react-keycloak';
import produce from 'immer';
import { useMemo } from 'react';

// Original lodash `set` converts object to array, if setting number keys.
function set(obj, path, value) {
  path.forEach((key, index) => {
    if (index + 1 === path.length) {
      obj[key] = value;
    } else {
      obj = obj[key] ??= {};
    }
  });
}

export default function usePrefillKeycloakFormData({ formData, config }) {
  const user = useCurrentUser();

  const prefilledFormData = useMemo(() => {
    if (user) {
      return produce({ formData }, (draft) => {
        (function prefillKeycloakData(config, path) {
          if (typesWithElements.includes(config.type)) {
            for (const element of config.elements ?? []) {
              prefillKeycloakData(element, [...path, element.key]);
            }
          } else {
            if (
              config.fillIdentificationInfo &&
              config.identificationInfoType
            ) {
              set(draft, path, user[config.identificationInfoType]);
            }
          }
        })(config, ['formData']);
      }).formData;
    }
    // Prefill formData only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return user ? prefilledFormData : formData;
}
