import { LinearProgress } from '@material-ui/core';
import { Layout } from '@super-template/core/src/LayoutFallback';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';
import Theme from '@super-template/theme';
import { setLegacyMode } from '@visma/formula/lib/legacyMode';
import AppProvider from '@visma/react-app-super-template';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import FormulaConfig from './FormulaConfig';
import Page from './layouts/Page';
import Routes from './Routes';

setLegacyMode();

export default function App() {
  return (
    <Theme>
      <AppProvider fallback={<LinearProgress />}>
        <SnackbarProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Layout FallbackComponent={Page}>
              <FormulaConfig>
                <BrowserRouter>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Routes />
                  </QueryParamProvider>
                </BrowserRouter>
              </FormulaConfig>
            </Layout>
          </ErrorBoundary>
        </SnackbarProvider>
      </AppProvider>
    </Theme>
  );
}
