import { defineMessages } from 'react-intl';

// App spesific common messages
export default defineMessages({
  admin: { defaultMessage: 'Hallinta' },
  back: { defaultMessage: 'Takaisin' },
  home: { defaultMessage: 'Etusivu' },
  forms: { defaultMessage: 'Kyselyt' },
  formGroups: { defaultMessage: 'Kysymysryhmät' },
  fields: { defaultMessage: 'Kysymykset' },
  port: { defaultMessage: 'Vienti ja tuonti' },
  importHeader: { defaultMessage: 'Tuonti' },
  exportHeader: { defaultMessage: 'Ulosvienti' },
  removeSelections: { defaultMessage: 'Poista valinnat' },
  selectAll: { defaultMessage: 'Valitse kaikki' },
  exportLink: { defaultMessage: 'Lataa valitut lomakkeet' },
  importAlert: { defaultMessage: 'Tuo lomakkeet.' },
  importSuccess: { defaultMessage: 'Lomakkeiden tuonti suoritettu!' },
  submit: { defaultMessage: 'Lähetä' },
  search: { defaultMessage: 'Hae' },
  empty: { defaultMessage: 'Tyhjennä' },
});
