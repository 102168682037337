import { useParams } from 'react-router-dom';
import Page from 'components/layouts/Page';
import { Form as FormulaForm, useFormRev } from '@visma/formula';

export const path = '/formdata/:formId/:formRev/:formDataId';

export const component = function FormData() {
  const { formId, formRev, formDataId } = useParams();

  const form = useFormRev(formId, formRev, formDataId);

  return (
    <Page title={form.title?.fi}>
      <FormulaForm id={formId} rev={formRev} dataId={formDataId} />
    </Page>
  );
};
