import { Suspense } from 'react';
import PageWithStaticHeader from '@super-template/lab/src/PageWithStaticHeader';
import { CircularProgress } from '@material-ui/core';
import AppBar from './AppBar';
import useSetDocumentTitle from './useSetDocumentTitle';
import Footer from './Footer';

export default function Page({ title, children }) {
  useSetDocumentTitle(title);

  return (
    <PageWithStaticHeader>
      <PageWithStaticHeader.AppBar>
        <AppBar title={title} />
      </PageWithStaticHeader.AppBar>
      <PageWithStaticHeader.Content>
        <Suspense fallback={<CircularProgress />}>{children}</Suspense>
        <Footer />
      </PageWithStaticHeader.Content>
    </PageWithStaticHeader>
  );
}
