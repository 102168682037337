import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMaterialUILocale from '@visma/react-app-locale-utils/lib/useMaterialUILocale';
import { useMemo } from 'react';
import useDarkMode from 'use-dark-mode';
import './main.css';

export default function Theme({ darkMode, children }) {
  const darkModeObject = useDarkMode();
  darkMode ??= darkModeObject.value;
  const locale = useMaterialUILocale();

  const theme = useMemo(
    () =>
      createMuiTheme(
        {
          palette: {
            type: darkMode ? 'dark' : 'light',
            primary: {
              main: '#0077C7',
            },
            secondary: {
              main: darkMode ? '#FFFFFF' : '#E70641',
            },
          },
          overrides: {
            MuiAlert: {
              message: {
                width: '100%',
              },
            },
          },
        },
        locale
      ),
    [darkMode, locale]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
